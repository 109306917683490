
import ApiService from '@/core/services/ApiService';
import { defineComponent, ref } from 'vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { useBus } from '../../../../bus';
import { Field } from 'vee-validate';
import axios from 'axios';
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent';
import { VueCookieNext } from 'vue-cookie-next';

export default defineComponent({
  name: 'kt-drawer-addsalesmanInfo',
  components: { Field },

  data() {
    return {
      load: false,
      userInfo: '',
      formData: {
        name:'',
        address:'',
        contact_person:'',
        designation:'',
        phone:'',
        email:'',
        nid:'',
      },

      countryList: [] as any,
      salesmanList: [] as any,
      finmanLedgerList: [] as any,
      finmanGroupList: [] as any,
      loading: false,
    };
  },
  async created() {
    this.load = true;
    await this.getCountryList();
    // let user_id = VueCookieNext.getCookie('_seip_user');
    // this.userInfo = user_id.id;
  },
  methods: {
    async getCountryList() {
      this.load = true;
      await ApiService.get('finmanInvoice/product/countryList')
        .then((response) => {
          this.countryList = response.data.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          this.load = false;
        });
    },
    async formSubmit() {
      let formData = new FormData();
      for (var key in this.formData) {
        formData.set(key, this.formData[key]);
      }

      this.loading = true;
      await ApiService.post('finmanInvoice/salesman/save', formData)
        .then((response) => {
          DrawerComponent?.hideAll();
          this.loading = false;
          if (response.status == 200) {
            this.emitter.emit('salesman-added', true);
            Swal.fire({
              title: 'Success!',
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              this.formData = {
                name:'',
                address:'',
                contact_person:'',
                designation:'',
                phone:'',
                email:'',
                nid:'',
              };
            });
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: 'Please check all the required field',
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: 'Unknown error',
            html: response.data.error,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },
  },
});

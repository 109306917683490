
import { defineComponent, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import moment from 'moment';
import KTAside from '@/layout/aside/courseSettings/Aside.vue';
import KTHeader from '@/layout/header/Header.vue';
import KTFooter from '@/layout/footer/Footer.vue';
import HtmlClass from '@/core/services/LayoutService';
import KTScrollTop from '@/layout/extras/ScrollTop.vue';
import KTUserMenu from '@/layout/header/partials/ActivityDrawer.vue';
import EditCategoryDrawer from '@/layout/header/partials/course/EditCategoryDrawer.vue';
import AddCategoryDrawer from '@/layout/header/partials/course/AddCategoryDrawer.vue';
import AddSupplierDrawer from '@/layout/header/partials/course/AddSupplierDrawer.vue';
import AddProductDrawer from '@/layout/header/partials/course/AddProductDrawer.vue';
import EditproductInfoDrawer from '@/layout/header/partials/course/EditproductInfoDrawer.vue';
import SetupProductInfoDrawer from '@/layout/header/partials/course/SetupProductInfoDrawer.vue';
import SetupRawProductInfoDrawer from '@/layout/header/partials/course/SetupRawProductInfoDrawer.vue';
import PurchaseInfoDrawer from '@/layout/header/partials/course/PurchaseInfoDrawer.vue';
import SalesInfoDrawer from '@/layout/header/partials/course/SalesInfoDrawer.vue';
import AddUnitDrawer from '@/layout/header/partials/course/AddUnitDrawer.vue';
import ViewProductDrawer from '@/layout/header/partials/course/ViewProductDrawer.vue';
import AddCustomerDrawer from '@/layout/header/partials/course/AddCustomerDrawer.vue';
import AddSalesmanDrawer from '@/layout/header/partials/course/AddSalesmanDrawer.vue';

// import KTAddCourseType from '@/layout/header/partials/course/AddCourseDrawer.vue';
// import KTEditCourseType from '@/layout/header/partials/course/EditCourseDrawer.vue';
// import KTAddCategory from '@/layout/header/partials/course/AddCategoryDrawer.vue';
// import KTEditCategory from '@/layout/header/partials/course/EditCategoryDrawer.vue';
// import KTAddSector from '@/layout/header/partials/course/AddSectorDrawer.vue';
// import KTEditSector from '@/layout/header/partials/course/EditSectorDrawer.vue';
// import KTAddMilestone from '@/layout/header/partials/course/AddMilestone.vue';
// import KTEditMilestone from '@/layout/header/partials/course/EditMilestone.vue';

// import KTCourseDrawer from '@/layout/header/partials/course/CourseInfoDrawer.vue';

import KTLoader from '@/components/Loader.vue';
import KTCreateApp from '@/components/modals/wizards/CreateAppModal.vue';
import KTDrawerMessenger from '@/layout/extras/DrawerMessenger.vue';
import { Actions } from '@/store/enums/StoreEnums';
import {
  MenuComponent,
  DrawerComponent,
  ScrollComponent,
} from '@/assets/ts/components/index';
import { removeModalBackdrop } from '@/core/helpers/dom';
import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo,
} from '@/core/helpers/config';

export default defineComponent({
  name: 'Layout',
  components: {
    KTAside,
    KTHeader,
    KTFooter,
    KTScrollTop,
    KTCreateApp,
    KTUserMenu,
    KTDrawerMessenger,
    KTLoader,
    AddCategoryDrawer,
    EditCategoryDrawer,
    AddProductDrawer,
    EditproductInfoDrawer,
    SetupProductInfoDrawer,
    SetupRawProductInfoDrawer,
    PurchaseInfoDrawer,
    SalesInfoDrawer,
    AddUnitDrawer,
    ViewProductDrawer,
    AddCustomerDrawer,
    AddSalesmanDrawer,
    AddSupplierDrawer,
    // KTAddMilestone,
    // KTEditMilestone,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading');

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    onMounted(() => {
      //check if current user is authenticated
      // if (!store.getters.isUserAuthenticated) {
      //   router.push({ name: 'sign-in' });
      // }

      DrawerComponent.bootstrap();
      ScrollComponent.bootstrap();
      DrawerComponent.updateAll();
      ScrollComponent.updateAll();

      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading');
      }, 500);
    });

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);

        DrawerComponent.hideAll();

        // check if current user is authenticated
        // if (!store.getters.isUserAuthenticated) {
        //   router.push({ name: 'sign-in' });
        // }

        removeModalBackdrop();
      }
    );

    return {
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      breadcrumbs,
      themeLightLogo,
      themeDarkLogo,
      moment,
    };
  },
});

const CourseSettingsMenu = [
  {
    heading: 'menu',
    route: '/add-invoice',
    pages: [
      {
        sectionTitle: 'Sales Invoice',
        svgIcon: 'media/icons/duotune/general/gen025.svg',
        fontIcon: 'bi-app-indicator',
        sub: [
          {
            heading: 'Add Sales Invoice',
            route: '/add-invoice',
            fontIcon: 'fa-user',
            svgIcon: '/media/icons/duotune/general/gen005.svg',
          },
          {
            heading: 'View Sales Invoices',
            route: '/sales-invoices',
            fontIcon: 'fa-user',
            svgIcon: '/media/icons/duotune/general/gen005.svg',
          },
          {
            heading: 'Sales Report',
            route: '/sales-report',
            fontIcon: 'fa-user',
            svgIcon: '/media/icons/duotune/general/gen005.svg',
          },
          {
            heading: 'Bill Collection',
            route: '/bill-collection',
            fontIcon: 'fa-user',
            svgIcon: '/media/icons/duotune/general/gen005.svg',
          },
        ],
      },
      {
        sectionTitle: 'Purchase Invoice',
        svgIcon: 'media/icons/duotune/general/gen025.svg',
        fontIcon: 'bi-app-indicator',
        sub: [
          {
            heading: 'Add Purchase Invoice',
            route: '/add-purchase',
            fontIcon: 'fa-user',
            svgIcon: '/media/icons/duotune/general/gen005.svg',
          },
          {
            heading: 'View Purchase Invoices',
            route: '/purchase-invoices',
            fontIcon: 'fa-user',
            svgIcon: '/media/icons/duotune/general/gen005.svg',
          },
        ],
      },

      {
        sectionTitle: 'Products',
        svgIcon: '/media/icons/duotune/general/gen035.svg',
        fontIcon: 'bi-app-indicator',
        sub: [
          {
            heading: 'Product Management',
            route: '/add-product',
            fontIcon: 'fa-user',
            svgIcon: '/media/icons/duotune/general/gen035.svg',
          },
          {
            heading: 'Sales & Purchase Types',
            route: '/product-category',
            fontIcon: 'fa-user',
            svgIcon: '/media/icons/duotune/general/gen008.svg',
          },
          {
            heading: 'Units',
            route: '/add-unit',
            fontIcon: 'fa-user',
            svgIcon: '/media/icons/duotune/general/gen031.svg',
          },
        ],
      },
      
      {
        heading: 'Customer Management',
        route: '/customers',
        fontIcon: 'fa-user',
        svgIcon: '/media/icons/duotune/general/gen017.svg',
      },
      {
        heading: 'Vendor/ Supplier Management',
        route: '/supplier',
        fontIcon: 'fa-user',
        svgIcon: '/media/icons/duotune/general/gen005.svg',
      },
      {
        heading: 'Sales-Man Management',
        route: '/salesman',
        fontIcon: 'fa-user',
        svgIcon: '/media/icons/duotune/general/gen024.svg',
      },

      
    ],
  },
];

export default CourseSettingsMenu;

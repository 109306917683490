
import ApiService from "@/core/services/ApiService";
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useBus } from "../../../../bus";
import { Field } from "vee-validate";
import { apiEndpoint } from '@/mixin/apiMixin.js';
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";
import moment from "moment";

export default defineComponent({
  mixins: [apiEndpoint],
  components: { Field , Datatable},

  data() {
    return {
      load: true,
      invoice:{} as any,
      invoiceDetails: [] as any,
      grand_total: 0,
      invoiceDetailsData: [] as any,
      loading: false,
      tableData: [] as any,
      componentKey: 0,
      moment: "" as any,
    };
  },
  async created() {
    this.emitter.on("sales-view-type", async (data) => {
      this.load = true;
      this.moment = moment;
      this.invoice= data.invoice;
      this.invoiceDetails= data.invoice_details;
      this.tableData= data.invoice_details;
      let grand_total_invoice= data.invoice_details.reduce(
        (accumulator, currentValue) => accumulator + currentValue['net_total'],
          0
      );
      this.grand_total= grand_total_invoice;
      console.log(this.invoice);
      this.componentKey += 1;
      this.load= false;
    });
  }
});

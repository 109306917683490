
import ApiService from "@/core/services/ApiService";
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useBus } from "../../../../bus";
import { Field } from "vee-validate";
import { apiEndpoint } from '@/mixin/apiMixin.js';
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";
import moment from "moment";

export default defineComponent({
  mixins: [apiEndpoint],
  components: { Field , Datatable},

  data() {
    return {
      moment: "" as any,
      load: false,
      invoice:[] as any,
      invoiceDetails: [] as any,
      grand_total: 0,
      invoiceDetailsData: [] as any,
      loading: false,
      tableData: [] as any,
      componentKey: 0
    };
  },
  async created() {
    this.emitter.on("purchase-view-type", async (data) => {
      this.moment = moment;
      this.invoice= data.invoice;
      this.invoiceDetails= data.invoice_details;
      this.tableData= data.invoice_details;
      // let grand_total_invoice= data.invoice_details.reduce(
      //   (accumulator, currentValue) => accumulator + currentValue['net_total'],
      //     0
      // );
      let grand_total_invoice = data.invoice_details.reduce((accumulator, currentValue) => {
          let atAmount = parseFloat(currentValue['at_amount']) || 0;
          let aitAmount = parseFloat(currentValue['ait_amount']) || 0;
          let vatAmount = parseFloat(currentValue['vat_amount']) || 0;
          let gTaxAmount = parseFloat(currentValue['g_tax_amount']) || 0;
          let vatImposeValue = parseFloat(currentValue['vat_impose_value']) || 0;
          return accumulator + atAmount + aitAmount + vatAmount + gTaxAmount + vatImposeValue;
      }, 0);
      this.grand_total= grand_total_invoice;
      this.componentKey += 1;
      this.load = true;
    });
  }
});

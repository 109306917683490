
import { defineComponent } from 'vue';
import { footerWidthFluid } from '@/core/helpers/config';

export default defineComponent({
  name: 'KTFooter',
  setup() {
    return {
      footerWidthFluid,
    };
  },
});
